import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCEEDED,
  LOGOUT_USER,
  SET_CURRENT_LOCALE,
} from '../constants';

export const loginUserSucceeded = (loginInfo) => ({ type: LOGIN_USER_SUCCEEDED, payload: loginInfo });
export const loginUserFailed = (loginInfo) => ({ type: LOGIN_USER_FAILED, payload: loginInfo });
export const loginUser = (userInfo) => ({ type: LOGIN_USER, payload: userInfo });
export const logoutUser = () => ({ type: LOGOUT_USER, payload: undefined });
export const setCurrentLocale = (locale) => ({ type: SET_CURRENT_LOCALE, payload: locale });
