import { componentStylesToCss } from '../componentStylesUtils';

const stylesJMEAddToFavourites = (componentStyles) => `
  .JMEAddToFavourites-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
  }
  .JMEAddToFavourites-icon {
    font-size: 1rem;
    margin-right: 5px;
  }
`;

export default stylesJMEAddToFavourites;
