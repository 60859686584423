import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Loading } from '../components';
import useShallowEqualSelector from '../components/core/useShallowEqualSelector';
import useModuleSettings from '../global-hooks/useModuleSettings';
import { logoutUser } from '../global-state/redux/actions';
import storageAndCookie from '../global-utils/storage-and-cookies';

const LogoutPage = () => {
  const isUserLogged = useShallowEqualSelector((state) => state?.user?.login_info?.user_id);
  const prevLoacation = storageAndCookie.get('previousLocation') || '/';
  const { instance } = useModuleSettings('JMTLogin');
  const instanceUser = `${instance}.user`;

  const dispatch = useDispatch();

  function deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }

  useEffect(() => {
    dispatch(logoutUser());
    storageAndCookie.del(instanceUser);
    deleteAllCookies();
  }, []);

  if (!isUserLogged) {
    window.location = prevLoacation;
    return null;
  }

  if (isUserLogged) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Loading />
      </div>
    );
  }
  return null;
};

export default LogoutPage;
