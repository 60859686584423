import { vmTypes } from '../global-prop-types';
import { isArr, isObj, traverseTree } from './globalUtils';
import { getValueByVmPath } from './vmPath';

/**
 * Scan dataMap for data mapping directives and replace by relevant values.
 * @description This funciton scans dataMap object for mapping directives. Whenever it comes across
 * object with property vmTypes.dataMapping it replaces the object iwth relevant value. To do the mapping the object
 * needs to have at least two properies: vmType and vmPath.
 * If dataMap is a nested object or array it traverses any property/element and if replaces it with value.
 * The values are taken from dataOb accordint to path specified in vmPath property. If path points to undefined, the fallback
 * value is assigned instead.
 * @param {object|array} dataObj object with data to follow according to vmPath
 * @param {object|array} dataMap mapping definitions
 * @param {any} fallbackValue a value assign to property or array element when vmPath returns undefined or null
 * @returns same structure as dataMap but with values brought from dataObj.
 */
// eslint-disable-next-line import/prefer-default-export
export function mapData(dataObj, dataMap, fallbackValue) {
  if (
    !(isObj(dataMap) || isArr(dataMap))
    || !(isObj(dataObj) || isArr(dataObj))
  ) {
    return undefined;
  }
  // using ?? is important. We should not treat empty strings, zeros as not a valid value:
  const getFallbackValue = (val, objClone) => val ?? objClone.fallbackValue ?? fallbackValue;

  const nodeCallback = (objClone) => {
    if (objClone?.vmType === vmTypes.dataMapping) {
      const value = getValueByVmPath(dataObj, objClone.vmPath);
      if (typeof value === 'function') {
        // When we call on function, we might want to return a result fo the function call
        // or a reference to the functions. If getFuncReference is provided then return
        // reference to func otherwise return result of the func call.
        if (value.getFuncReference || value.isReduxAction) {
          return { func: value, args: objClone.args ?? [] };
        }
        return getFallbackValue(value(...(objClone.args ?? [])), objClone);
      }
      return getFallbackValue(value, objClone);
    }
    return objClone;
  };

  return traverseTree(dataMap, nodeCallback);
}

export function createDataMap(vmPath = [], options = {}) {
  const { args } = options;
  const dataMap = {
    vmType: vmTypes.dataMapping,
    vmPath,
  };
  if (args) dataMap.args = args;

  return dataMap;
}
