import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../components';
import useShallowEqualSelector from '../components/core/useShallowEqualSelector';
import { ocaLogin } from '../global-state/redux/actions/app-state-actions';

const OcaPage = () => {
  const isUserLogged = useShallowEqualSelector((state) => state?.user?.login_info?.user_id);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUserLogged) {
      dispatch(ocaLogin(params));
    }
  }, []);

  const navigateToIndex = () => {
    navigate('/');
    return null;
  };

  if (isUserLogged) {
    return navigateToIndex();
  }

  if (!isUserLogged) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Loading />
      </div>
    );
  }
  return null;
};

export default OcaPage;
