import { lazy } from 'react';

// Text
export const JmeText = lazy(() => import('./JmeText'));
export const JmeInputBase = lazy(() => import('./JmeInputBase'));
export const DynamicContent = lazy(() => import('./DynamicContent'));
export const TextHref = lazy(() => import('./TextHref'));
export const JmeAvatar = lazy(() => import('./JmeAvatar'));
export const JmeLabel = lazy(() => import('./JmeLabel'));
export const JmeRatingStars = lazy(() => import('./JmeRatingStars'));
export const JmeAlert = lazy(() => import('./JmeAlert'));
