import { lazy } from 'react';

// Others
export const DateFormatter = lazy(() => import('./DateFormatter'));

export const JMEAddToFavourites = lazy(() => import('./JmeAddToFavourites'));
export const ResourceImagePreview = lazy(() => import('./ResourceImagePreview'));
export const JMESendMessageToAuthor = lazy(() => import('./JmeSendMessageToAuthor'));
export const CourseImage = lazy(() => import('./CourseImage'));

export const QnaVoting = lazy(() => import('./QnaVoting'));
export const QnaVotingHtml = lazy(() => import('./QnaVotingHtml'));
export const JMETabs = lazy(() => import('./JMETabs'));
export const JmeHtml5Player = lazy(() => import('./JmeHtml5Player'));
export const JmeOnClick = lazy(() => import('./JmeOnClick'));
export const FilterTypeContent = lazy(() => import('./FilterTypeContent'));
export const TimeDiffDuration = lazy(() => import('./TimeDiffDuration'));
export const JmeVideoPlayer = lazy(() => import('./JmeVideoPlayer'));
export const ResourcesFiltering = lazy(() => import('./ResourcesFiltering'));
export const FirstAvailiablePresentation = lazy(() => import('./FirstAvailiablePresentation'));
export const MediaMetaInfo = lazy(() => import('./MediaMetaInfo'));
export const JmeNavbar = lazy(() => import('./JmeNavbar'));
export const IsUserLogged = lazy(() => import('./IsUserLogged'));
