import { mapData } from '../../dataMapping';
import vmFunctions from '..';
import {
  getPropsMask,
  getStoreMask,
  ifToDebounceApiCall,
} from '../../../global-hooks/useAPIDataHook';
import {
  apiRequestFinished,
  apiRequestProcessor,
} from '../../../global-state/redux/actions';

const makeApiCall = (apiDataInfo = []) => (dispatch, getState) => {
  const store = getState();
  const storeData = store.data;
  const dataBank = { store, vmFunctions };
  apiDataInfo.forEach((apiDataInfoItem) => {
    const entryReplaced = mapData(dataBank, apiDataInfoItem);
    const propsMask = getPropsMask(entryReplaced);
    const storeMask = getStoreMask(entryReplaced);
    const apiDataInfoItemClone = {
      ...entryReplaced,
      propsMask,
      storeMask,
    };
    if (!ifToDebounceApiCall(storeData, apiDataInfoItemClone)) {
      dispatch(apiRequestProcessor(apiDataInfoItemClone));
    } else {
      console.log('Debouncing API call:', storeMask);
      // We made a request to bring API responce from call that was already made.
      // However, the data under the propsMask can coem from a call with different
      // request body. Next line makes sure, we bring data from history to propsMask
      dispatch(apiRequestFinished(propsMask, { ...storeData[storeMask] }));
    }
  });
};

makeApiCall.isReduxAction = true;

// eslint-disable-next-line import/prefer-default-export
export { makeApiCall };
