const storageAndCookie = {
  localStoreSupport() {
    try {
      return 'localStorage' in window && window.localStorage !== null && this.isLocalStorageNameSupported();
    } catch (e) {
      return false;
    }
  },
  isLocalStorageNameSupported() {
    const testKey = 'test'; const
      storage = window.sessionStorage;
    try {
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      // return localStorageName in win && win[localStorageName];
      return true;
    } catch (error) {
      return false;
    }
  },
  set(name, value, days, toCookie = false) {
    let expires;
    let date = new Date();
    if (days) {
      if (typeof days === 'object') { // means we sent a date already;
        date = days;
      } else {
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      }
      // console.warn("Set storage: date", date)
      expires = `; expires=${date.toGMTString()}`;
    } else {
      expires = '';
    }
    if (this.localStoreSupport() && !toCookie) {
      localStorage.setItem(name, value);
    } else {
      document.cookie = `${name}=${value}${expires}; path=/`;
    }
  },
  get(name, fromCookie = false) {
    let ret = localStorage.getItem(name);
    if (this.localStoreSupport() && !fromCookie) {
      // cl(typeof ret);
      switch (ret) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return ret;
      }
    } else {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) {
          ret = c.substring(nameEQ.length, c.length);
          switch (ret) {
            case 'true':
              return true;
            case 'false':
              return false;
            default:
              return ret;
          }
        }
      }
      return null;
    }
  },
  del(name, fromCookie = false) {
    if (this.localStoreSupport() && !fromCookie) {
      localStorage.removeItem(name);
    } else {
      this.set(name, '', -1, fromCookie);
    }
  },
};

export default storageAndCookie;
