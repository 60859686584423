/**
 * Set of functions that manipulate with API calls from:
 *  - v1/media_items.json
 *  - v1/media_items/filters.json
 */

import { webinarToMediaCard, WEBINAR_MEDIABLE_TYPE } from './apiWebinars';
import { courseToMediaCard, COURSE_MEDIABLE_TYPE } from './apiCourses';
import { resourceToMediaCard, RESOURCE_MEDIABLE_TYPE } from './apiResources';

/**
 * Converts generic API response to UI MediaCard object.
 * @param {Object} item an item of the API response. Can be any that complys with wxpwcted structure
 * @param {Object} keywordToColorMap map that converts a string to color.
 * @returns {Object} an object with properties as expected by UI media card.
 */
function genericItemToMediaCard(item = {}) {
  return {
    ...item,
    // id: item?.id,
    // title: item?.title,
    endsAt: item?.ends_at,
    image: item?.preview_url,
    startsAt: item?.starts_at,
    subTitle: item?.sub_title,
    views: item?.playbacks_count,
    // href: handeled individually by each convertion calls
    // mediableTypeColor: handeled individually by each convertion call
  };
}

/**
 * convert media item returned from media_items call to UI MediaCard.
 * @description Mixing resource_groups wit other media item itmes creates difficult situation
 * of mapping. Most of the items in mediaItems array are single objects. However the resourceGrousp
 * had an array of resources. Therefore the webinar, course and fe other return single object, that
 * here we coerct to array. Resource groups return arrays.
 */
function mediaItemToMediaCard(mediaItem = {}, mediaLibrarySettings = {}) {
  const { mediable_type: mediableType } = mediaItem;
  const options = mediaLibrarySettings[mediableType];
  switch (mediableType) {
    case WEBINAR_MEDIABLE_TYPE:
      return [webinarToMediaCard(mediaItem?.body, options)];
    case COURSE_MEDIABLE_TYPE:
      return [courseToMediaCard(mediaItem?.body, options)];
    case RESOURCE_MEDIABLE_TYPE:
      return mediaItem.body?.resources
        ?.filter((el) => el.type === 'Content')
        .map((el) => resourceToMediaCard(el, mediaLibrarySettings));
    default:
      return [];
  }
}

// For wexplanation why we use reduce and spread arrays see comment to mediaItemToMediaCard function.
function mediaItemsToMediaCard(mediaItems = [], mediaLibrarySettings = {}) {
  return mediaItems
    .filter((el) => !!el.body)
    .reduce(
      (acc, el) => [...acc, ...mediaItemToMediaCard(el, mediaLibrarySettings)],
      []
    )
    .filter((el) => el.id); // card must have id for the link
}

function apiBodyFromUrlSearchParams(defaultBody = {}) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const apiBody = { ...defaultBody };
  urlSearchParams.forEach((val, key) => {
    apiBody[key] = val;
  });
  return apiBody;
}

export {
  genericItemToMediaCard,
  mediaItemsToMediaCard,
  apiBodyFromUrlSearchParams,
};
