import { useEffect, useState } from 'react';
import getDeviceConfig from '../global-utils/deviceConfig';
import useTheme from './useTheme';

const useBreakpoint = () => {
  const theme = useTheme();
  const { breakpoints } = theme;

  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth, breakpoints || {}));

  useEffect(() => {
    const calcInnerWidth = () => {
      setBrkPnt(getDeviceConfig(window.innerWidth, breakpoints || {}));
    };
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;
