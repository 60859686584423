const isExists = (value) => typeof value !== 'undefined';

export const extendObj = (mainObj, secObj, exOptions = {}, deep = []) => {
  // currently using deep only to debug.
  const { skipExisting = false, allowExtending = false } = exOptions;
  let tmpMain;
  const isArr = Array.isArray(secObj);
  if ((typeof secObj === 'object' || isArr) && secObj !== null) {
    if (!isExists(mainObj) && allowExtending) { // if allowed extending
      // If the main object doesn't have it.. just set
      tmpMain = secObj;
    } else if (mainObj !== null) {
      // main has it, let's go deeper
      tmpMain = isArr ? [...mainObj] : { ...mainObj };
      if (isExists(secObj)) {
        Object.keys(secObj).forEach((key) => {
          // When coming back from normal value it will check the SkipExisting
          // if doesn't exists and allow extend
          if (mainObj && !isExists(mainObj[key]) && allowExtending) {
            tmpMain[key] = secObj[key];
          } else if (mainObj && isExists(mainObj[key])) { // if exists - then extend
            tmpMain[key] = extendObj(mainObj[key], secObj[key], exOptions, [...deep, key]);
          } else {
            // if doesn't exists, and NO extend,
            // lands here, if the secObj has fields that the main doesn't have
            // tmpMain = mainObj
          }
        });
      } else {
        tmpMain = mainObj;
      }
    }
  } else if (isExists(mainObj) && !skipExisting) {
    // its normal param.
    tmpMain = secObj;
  } else {
    tmpMain = mainObj;
  }

  return tmpMain;
};

export default extendObj;
