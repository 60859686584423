import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeInputBase = (componentStyles) => `
  .jme-inputBase-wrapper {
    border: none;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
`;

export default stylesJmeInputBase;
