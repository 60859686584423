import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useShallowEqualSelector from './components/core/useShallowEqualSelector';
import {
  prepareApp,
  setCurrentLocale,
} from './global-state/redux/actions';
import { Loading } from './components';
import FullApp from './FullApp';
import IframeCommunicator from './IframeCommunicator';

const App = () => {
  const {
    isReady, isCriticalError, isIframe, settings
  } = useShallowEqualSelector(
    (state) => state.appState,
  );
  const favicon = settings?.appSettings?.favicon;
  const currentLocale = settings?.appSettings?.locale?.currentLocale;

  // console.log('isIframe', isIframe);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(prepareApp());
  }, [dispatch]);

  const handleFavicon = () => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = favicon;
  };

  useEffect(() => {
    if (isReady) {
      handleFavicon();
      if (currentLocale) dispatch(setCurrentLocale(currentLocale));
    }
  }, [isReady]);

  if (isCriticalError) {
    // TODO: make critical error nicer
    return <div>ERROR GETTING SETTINGS SCREEN...</div>;
  }
  if (!isReady) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100vh'
        }}
      >
        {isIframe && <IframeCommunicator isInit />}
        <Loading />
      </div>
    );
  }
  return <FullApp isIframe={isIframe} />;
};

export default App;
