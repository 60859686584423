import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IconComp } from '../Layout';
import { setClientData } from '../../global-state/redux/actions';
import { componentsPropType, stylesPropType, vmPropTypes } from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';

const JmeOverlayer = (props) => {
  const {
    styles, jmeIsOpen, forceOpen, ...restProps
  } = props;
  const isOpen = useSelector((store) => store.appState.clientData[jmeIsOpen]);
  const dispatch = useDispatch();

  const handleCloseClick = () => {
    dispatch(setClientData(jmeIsOpen, false));
  };

  if (!isOpen && !forceOpen) return null;
  return (
    <div className="jme-overlayer-wrapper" style={styles?.wrapper}>
      <div className="jme-overlayer-modal" style={styles?.modal}>
        <div
          className="jme-overlayer-closeButtonWrapper"
          style={styles?.closeButtonWrapper}
        >
          <div
            role="button"
            onClick={handleCloseClick}
            onKeyPress={handleCloseClick}
            tabIndex={0}
            className="jme-overlayer-button"
          >
            <IconComp icon={{ iconType: 'fa', name: 'times' }} />
          </div>
        </div>
        <ComponentsArrRenderer {...restProps} />
      </div>
    </div>
  );
};

JmeOverlayer.propTypes = {
  styles: stylesPropType,
  jmeIsOpen: PropTypes.string.isRequired,
  components: componentsPropType,
  forceOpen: PropTypes.bool,
};

JmeOverlayer.defaultProps = {
  components: [],
  styles: {
    wrapper: {},
    modal: {},
    closeButtonDiv: {},
  },
  forceOpen: false
};
JmeOverlayer.vmPropTypes = {
  components: vmPropTypes.components,
  styles: {
    wrapper: vmPropTypes.styles,
    modal: vmPropTypes.styles,
    closeButtonDiv: vmPropTypes.styles,
  },
  jmeIsOpen: vmPropTypes.string,
  forceOpen: vmPropTypes.boolean
};

export default JmeOverlayer;
