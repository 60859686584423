import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter,
} from 'react-router-dom';
import useShallowEqualSelector from './components/core/useShallowEqualSelector';
import SplashScreen from './components/screens/SplashScreen';
import AppWrapper from './components/AppWrapper';
import StylesBuilder from './components-styles';
import * as allComps from './components/index';
import IframeCommunicator from './IframeCommunicator';

const FullApp = (props) => {
  const { isIframe } = props;
  const { splashScreen } = useShallowEqualSelector((state) => state.appState.settings);
  const [isSplash, setIsSplash] = useState(false);

  useEffect(() => {
    if (splashScreen) {
      const { exists, durationInMs } = splashScreen;
      if (exists) {
        const timeout = Number(durationInMs) || 3000;
        setIsSplash(true);
        setTimeout(() => {
          setIsSplash(false);
          console.log('TIMEOUT FullApp');
        }, timeout);
      }
    }
  }, []);

  return (
    <>
      {isIframe && <IframeCommunicator />}
      <StylesBuilder />
      <BrowserRouter>
        {isSplash
          ? <SplashScreen {...splashScreen} allComps={allComps} />
          : <AppWrapper allComps={allComps} />}
      </BrowserRouter>
    </>
  );
};
FullApp.propTypes = {
  isIframe: PropTypes.bool.isRequired,
};

export default FullApp;
