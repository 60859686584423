import { apiRequest } from '../../../global-utils/api-request';
import {
  API_REQUEST_STARTED,
  API_REQUEST_ERROR,
  API_MANUAL_DATA_UPDATE,
  API_REQUEST_FINISHED,
  API_REQUEST_DELETE_DATA,
} from '../constants';

export const apiRequestStarted = (parent) => ({
  type: API_REQUEST_STARTED,
  payload: { parent },
});
export const apiRequestError = (parent, err) => ({
  type: API_REQUEST_ERROR,
  payload: { parent, err },
});
export const apiManualDataUpdate = (parent, data) => ({
  type: API_MANUAL_DATA_UPDATE,
  payload: { parent, data },
});
export const apiRequestFinished = (parent, { data, headers }) => ({
  type: API_REQUEST_FINISHED,
  payload: { parent, data, headers },
});
export const apiRequestDeleteData = (parent) => ({
  type: API_REQUEST_DELETE_DATA,
  payload: { parent },
});

/* END OF DIFFERENT FILE */
export const apiRequestProcessor = (apiDataInfo = {}) => (dispatch, getState) => {
  const {
    propsMask, storeMask, body, ...restInfo
  } = apiDataInfo;
  const { appState } = getState((store) => store);
  const url = `${window.location.protocol}//${appState.core.server_addr}${apiDataInfo.url}`;
  dispatch(apiRequestStarted(storeMask));
  apiRequest({ ...restInfo, url, body, })
    .then((res) => {
      dispatch(apiRequestFinished(propsMask, res));
      dispatch(apiRequestFinished(storeMask, res));
    })
    .catch((e) => {
      dispatch(apiRequestError(storeMask, e));
    });
};
