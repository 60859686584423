import { componentStylesToCss } from '../componentStylesUtils';

const stylesIconComp = (componentStyles) => `
  .iconComp-muiWrapper {
    font-size: 30px;
    ${componentStylesToCss(componentStyles?.muiWrapper)}
  }
  .iconComp-faWrapper {
    display: flex;
    font-size: 30px;
    ${componentStylesToCss(componentStyles?.awesomeWrapper)}
  }
  .iconComp-imgWrapper {
    width: 30px;
    height: 30px;
    ${componentStylesToCss(componentStyles?.imgWrapper)}
  }
`;

export default stylesIconComp;
