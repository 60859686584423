// Here we will put our USER INFO
import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCEEDED,
  LOGOUT_USER,
  SET_CURRENT_LOCALE,
} from '../constants';

export const initialUser = {
  login_info: {
    isLoggedIn: false,
    id: undefined,
    token: undefined,
    tickets: [], // Will be used for permissions
  },
  currentLocale: 'en'
  // There will be probably more stuff to do with the user...
  // Such as favorites, chair.. etc
};

const userReducer = (state = initialUser, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCEEDED:
      return {
        ...state,
        login_info: {
          loggedIn: true,
          ...action.payload,
        },
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        login_info: {
          loggedIn: false,
          ...action.payload,
        },
      };
    case LOGIN_USER:
      return {
        ...state,
        login_info: {
          ...state.login_info,
          ...action.payload,
        },
      };
    case LOGOUT_USER:
      return initialUser;
    case SET_CURRENT_LOCALE: {
      return {
        ...state,
        currentLocale: action.payload
      };
    }
    default:
      return state;
  }
};

export default userReducer;
