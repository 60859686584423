/* Collection of type definition for components properties

  CONVENTION:
    The value of a key it the CAPITALIZED_KEBAB_CASE string
    of the key with prefix `VM_PROPTYPE_`.

  Example:
    arrayOfStrings: VM_PROPTYPE_ARRAY_OF_STRINGS */

import { isObj, isStr } from '../global-utils/globalUtils';

const vmTypesSingle = {
  // primitive types:
  string: 'string',
  boolean: 'boolean',
  number: 'number',
  object: 'object',
  data: 'data',
  array: 'array',

  // common component props:
  components: 'components',
  styles: 'styles',
  uuid: 'VM_PROPTYPE_UUID',

  // properties that need custom editors:
  icon: 'icon',
  htmlText: 'htmlText',
  color: 'color',
  image: 'image',

  // Types for models: the value for the model has to be a key in adminModels!
  modelCourses: 'courses',
  modelCategories: 'categories',
  modelGuidelines: 'guidelines',
  modelNews: 'news',
  modelSponsors: 'sponsors',
  modelSponsorsGroups: 'sponsorsGroups',
  modelCourseCategories: 'courseCategories',
  modelTopics: 'topics',
  modelWebinar: 'webinar',

  // others:
  apiData: 'VM_PROPTYPE_API_DATA',
  apiContentRequest: 'VM_PROPTYPE_API_CONTENT_REQUEST',
  url: 'url',
  internalHref: 'internalHref',
  timestamp: 'date', // TODO: Change to VM_PROPTYPE_TIMESTAMP
  hideWhen: 'VM_PROPTYPE_HIDE_WHEN',
  columnSizesPerBreakpoint: 'columnSizesPerBreakpoint',

  // type to set vmType in editors:
  vmTypeSelect: 'VM_PROPTYPE_VM_TYPE_SELECT',
  dataMapping: 'VM_TYPE_DATA_MAPPING',

  // component spacific types:
  collapsable: 'collapsable',
  prosCons: 'prosCons',
  faq: 'faq'
};

const vmTypesArrays = Object.keys(vmTypesSingle).reduce(
  (acc, key) => ({
    ...acc,
    [`arrayOf${key.replace(/^[\w]/, (firstLetter) => firstLetter.toUpperCase())}`]: {
      type: vmTypesSingle.array,
      arrayItemVmType: vmTypesSingle[key]
    }
  }),
  {}
);

const vmTypes = {
  ...vmTypesSingle,
  ...vmTypesArrays
};

const getVmTypeByKey = (vmTypesObj) => {
  // vmTypesObj is an object or a string representgin a key
  // from vmTypes.
  if (isStr(vmTypesObj)) return vmTypes[vmTypesObj];
  if (isObj(vmTypesObj)) {
    return Object.keys(vmTypesObj).reduce(
      (acc, key) => ({
        ...acc,
        [key]: getVmTypeByKey(vmTypesObj[key])
      }),
      {}
    );
  }
  return undefined;
};

const isVmType1CompatibleWithVmType2 = (vmType1, vmType2) => {
  // Check if one vmType is compatible with the other, eg. string can accept in
  // but int cannot accept string
  let ret = false;
  if (vmType2 === vmType1) ret = true;

  if (vmType2 === vmTypesSingle.array) {
    ret = vmType1.type === vmTypesSingle.array;
  }

  return ret;
};

// Checks if the variable is one of the defined vmTypes
const isVmType = (obj) => Object.values(vmTypes).some((vmType) => vmType === obj || vmType === obj?.vmType);
const vmTypeToKey = (vmType) => Object.keys(vmTypes).find((key) => vmTypes[key] === vmType)
  ?? 'unknown';

export {
  vmTypes,
  getVmTypeByKey,
  isVmType1CompatibleWithVmType2,
  isVmType,
  vmTypeToKey
};
