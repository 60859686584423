import React from 'react';
import PropTypes from 'prop-types';
import { stylesPropType } from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import AnyErrorBoundary from '../global/AnyErrorBoundary';

const HeadBanner = (props) => {
  const {
    styles,
    image,
    parent = [],
    ...restProps
  } = props;
  return (
    <AnyErrorBoundary>
      <div
        className="d-flex"
        style={styles?.wrapper}
      >
        <ComponentsArrRenderer
          parent={[...parent, 'HeadBanner']}
          {...restProps}
        />
      </div>
    </AnyErrorBoundary>
  );
};

HeadBanner.propTypes = {
  styles: stylesPropType,
  image: PropTypes.string,
  components: PropTypes.array,
  parent: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])),
};

HeadBanner.defaultProps = {
  styles: {
    wrapper: {
      background: 'linear-gradient(to right, #E52330BF, transparent)',
      height: '184px',
      borderRadius: '0px 0px 115px 0px',
      marginBottom: '32px'
    }
  }
};

export default HeadBanner;
