import { useEffect } from 'react';
import useTheme from '../global-hooks/useTheme';
import useShallowEqualSelector from '../components/core/useShallowEqualSelector';
import writeStyleToHead from '../global-utils/writeStylesToHead';
import { processComponentStyles } from './componentStylesUtils';

const StylesBuilder = () => {
  const { componentStyles = {} } = useShallowEqualSelector((state) => state.appState.settings);
  const theme = useTheme();

  useEffect(() => {
    // Bring styles from the layoutSettings.themSettings:
    const cssText = processComponentStyles(componentStyles, theme);
    writeStyleToHead(cssText);
  }, [componentStyles]);

  return null;
};

export default StylesBuilder;
