import { lazy } from 'react';

// Layout
export const TableData = lazy(() => import('./TableData'));
export const ImageComp = lazy(() => import('./ImageComp'));
export const IconComp = lazy(() => import('./IconComp'));
export const SvgComp = lazy(() => import('./SvgComp'));
export const CategorySquare = lazy(() => import('./CategorySquare'));
export const HorizontalScrollable = lazy(() => import('./HorizontalScrollable'));
export const ClickHref = lazy(() => import('./ClickHref'));
export const ExternalHref = lazy(() => import('./ExternalHref'));
export const JmeDivider = lazy(() => import('./JmeDivider'));
export const JmeBox = lazy(() => import('./JmeBox'));
export const Faqs = lazy(() => import('./Faqs'));
export const ProsCons = lazy(() => import('./ProsCons'));
export const JmeContainer = lazy(() => import('./JmeContainer'));

// Layout -> Grid
export const JmeGrid = lazy(() => import('./grid/JmeGrid'));
export const JmeGridWithSidePreview = lazy(() => import('./grid/JmeGridWithSidePreview'));
export const JmePreviewProvider = lazy(() => import('./grid/JmePreviewProvider'));
export const DataGrid = lazy(() => import('./grid/DataGrid'));
export const JmeGridDynamic = lazy(() => import('./grid/JmeGridDynamic'));
export const GridRow = lazy(() => import('./grid/GridRow'));
export const GridColumn = lazy(() => import('./grid/GridColumn'));

// Layout -> Carousel
export const CarouselComp = lazy(() => import('./Carousel/CarouselComp'));

// Layout -> Card
export const JmeCard = lazy(() => import('./Card/JmeCard'));
export const JmeCardContent = lazy(() => import('./Card/JmeCardContent'));
export const JmeCardActionArea = lazy(() => import('./Card/JmeCardActionArea'));
export const JmeCardActions = lazy(() => import('./Card/JmeCardActions'));
export const JmeCardMedia = lazy(() => import('./Card/JmeCardMedia'));
// Layout -> Guidlines
export const GuidelinesLink = lazy(() => import('./Guidelines/GuidelinesLink'));
export const GuidelinesTitle = lazy(() => import('./Guidelines/GuidelinesTitle'));
export const InfiniteScroll = lazy(() => import('./InfiniteScroll'));
