// Here is the state of our app
import getDeviceConfig from '../../../global-utils/deviceConfig';
import {
  APP_MOBILE_MENU_OPEN,
  APP_MOBILE_MENU_CLOSE,
  APP_MOBILE_MENU_TOGGLE,
  APP_TOPNAV_MENU_OPEN,
  APP_TOPNAV_MENU_CLOSE,
  APP_SET_READY,
  APP_SET_READY_ERROR,
  APP_SET_CORE,
  APP_CHANGE_PAGE,
  APP_CHANGE_BREAKPOINT,
  SET_CURRENT_LOCATION,
  SET_IS_IFRAME,
  SET_TEST_FLIGHT_PAGE,
  SET_TEST_FLIGHT_PAGE_PARAMS,
  SET_TEST_FLIGHT_COMPONENTS_BUILDING,
  SET_GRID_WITH_SIDE_PREVIEW,
  SET_EVENT_NOTIFICATIONS,
  SET_CLIENT_DATA,
  USER_AUTH_LOADING_END,
  USER_AUTH_LOADING_START,
} from '../constants';

export const initialData = {
  isMenuOpen: false,
  topNavMenuAnchor: null,
  isReady: false,
  isCriticalError: false,
  allCompsToCore: [],
  core: undefined, // will be an object of core settings
  settings: undefined, // will be the settings of our app
  currentPage: { // Current page info
    title: null,
    pageName: null,
  },
  currentBreakpoint: getDeviceConfig(window.innerWidth),
  currentAppUrl: '',
  isIframe: false,
  testFlightPageInfo: null,
  testFlightPageParams: [],
  testFlightComponentsBuilding: null,
  gridWithSidePreview: {
    isActive: false,
    content: {},
  },
  eventNotifications: {},
  clientData: {},
  isAuthLoading: false, // TODO: how not to make double API calls?
};

const apiReducer = (state = initialData, action) => {
  switch (action.type) {
    case SET_TEST_FLIGHT_PAGE:
      return {
        ...state,
        testFlightPageInfo: action.payload,
      };
    case SET_TEST_FLIGHT_PAGE_PARAMS:
      return {
        ...state,
        testFlightPageParams: action.payload,
      };
    case SET_TEST_FLIGHT_COMPONENTS_BUILDING:
      return {
        ...state,
        testFlightComponentsBuilding: action.payload,
      };
    case SET_IS_IFRAME:
      return {
        ...state,
        isIframe: true,
      };
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentAppUrl: action.payload,
      };
    case APP_MOBILE_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: true,
      };
    case APP_MOBILE_MENU_CLOSE:
      return {
        ...state,
        isMenuOpen: false,
      };
    case APP_MOBILE_MENU_TOGGLE:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      };
    case APP_TOPNAV_MENU_OPEN:
      return {
        ...state,
        topNavMenuAnchor: action.payload.topNavMenuAnchor,
      };
    case APP_TOPNAV_MENU_CLOSE:
      return {
        ...state,
        topNavMenuAnchor: null,
      };
    case APP_SET_READY:
      return {
        ...state,
        isReady: action.payload.isReady,
        settings: action.payload.settings,
        allCompsToCore: action.payload.allCompsToCore || [],
      };
    case APP_SET_CORE:
      return {
        ...state,
        core: action.payload,
      };
    case APP_SET_READY_ERROR:
      return {
        ...state,
        isReady: false,
        isCriticalError: true,
      };
    case APP_CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case APP_CHANGE_BREAKPOINT:
      return {
        ...state,
        currentBreakpoint: action.payload,
      };
    case SET_GRID_WITH_SIDE_PREVIEW:
      return {
        ...state,
        gridWithSidePreview: action.payload,
      };
    case SET_EVENT_NOTIFICATIONS:
      return {
        ...state,
        eventNotifications: {
          ...state.eventNotifications,
          ...action.payload
        }
      };
    case SET_CLIENT_DATA:
      return {
        ...state,
        clientData: {
          ...state.clientData,
          [action.payload.key]: action.payload.data,
        },
      };
    case USER_AUTH_LOADING_START:
      return {
        ...state,
        isAuthLoading: true,
      };
    case USER_AUTH_LOADING_END:
      return {
        ...state,
        isAuthLoading: false,
      };
    default:
      return state;
  }
};

export default apiReducer;
