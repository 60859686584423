import { lazy } from 'react';

// Core
export const RenderCoreComponents = lazy(() => import('./RenderCoreComponents'));
export const DynamicDataLooper = lazy(() => import('./DynamicDataLooper'));
export const DynamicContentLooper = lazy(() => import('./DynamicContentLooper'));
export const ApiRequestComponent = lazy(() => import('./ApiRequestComponent'));
export const ExternalComponentRenderer = lazy(() => import('./ExternalComponentRenderer'));
export const EventListener = lazy(() => import('./EventListener'));
export const PaginationWrapper = lazy(() => import('./PaginationWrapper'));
