import React from 'react';
import { stylesPropType, vmPropTypes } from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import AnyErrorBoundary from '../global/AnyErrorBoundary';

const Navbar = (props) => {
  const { styles, ...restProps } = props;

  return (
    <AnyErrorBoundary>
      <nav
        className="container-fluid px-lg-0 navbar navbar-expand-lg navbar-light p-0"
        style={{
          backgroundColor: '#fff',
          ...styles?.wrapper
        }}
      >
        <div className="container" style={styles?.toolbar}>
          <ComponentsArrRenderer {...restProps} parent={['Navbar']} />
        </div>
      </nav>
    </AnyErrorBoundary>
  );
};

Navbar.propTypes = {
  styles: stylesPropType,
};

Navbar.defaultProps = {
  styles: {
    wrapper: {},
    toolbar: {},
  },
};

Navbar.vmPropTypes = {
  styles: {
    wrapper: vmPropTypes.styles,
    toolbar: vmPropTypes.styles,
  },
};

export default Navbar;
