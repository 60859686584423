import useShallowEqualSelector from '../components/core/useShallowEqualSelector';
import { loadSingleModules, ResetSingleModulesImports } from '../global-utils/loadModules';

/**
 * useModuleSettings
 * @param {componentName |string} componentName  - should string - the exact name of your component
 * @returns the settings that are defined for the module.
 *
 */
const useModuleSettings = (componentName = '') => {
  if (!componentName) {
    console.log('Error Using Module Settings without a name');
    return null;
  }
  const { settings } = useShallowEqualSelector((state) => state.appState);

  // 1. scan modules - to find which module is using this componentName
  const modulesList = Object.keys(settings.modules);
  let foundModule;
  for (let i = 0; i < modulesList.length && !foundModule; i++) {
    const moduleEntry = modulesList[i];
    if (settings.modules[moduleEntry]?.componentsUsingModule.includes(componentName)) {
      // found the current module that is using this component.
      foundModule = moduleEntry;
    }
  }
  console.log('foundModule', foundModule, modulesList, settings);

  if (foundModule) {
    // 2. Make sure all import files are loaded.
    loadSingleModules(settings.modules[foundModule], true);

    // 3. return the settings of this module.
    return {
      ...settings.modules[foundModule].settings,
      reset: () => {
        ResetSingleModulesImports(settings.modules[foundModule]);
      }
    };
  }
  return null;
};

export default useModuleSettings;
