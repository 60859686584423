import { loadSingleModules } from '../../../global-utils/loadModules';
import {
  APP_MOBILE_MENU_OPEN,
  APP_MOBILE_MENU_CLOSE,
  APP_MOBILE_MENU_TOGGLE,
  APP_TOPNAV_MENU_OPEN,
  APP_TOPNAV_MENU_CLOSE,
  APP_SET_READY,
  APP_SET_READY_ERROR,
  APP_SET_CORE,
  APP_CHANGE_PAGE,
  APP_CHANGE_BREAKPOINT,
  SET_CURRENT_LOCATION,
  SET_IS_IFRAME,
  SET_TEST_FLIGHT_PAGE,
  SET_TEST_FLIGHT_PAGE_PARAMS,
  SET_TEST_FLIGHT_COMPONENTS_BUILDING,
  SET_GRID_WITH_SIDE_PREVIEW,
  SET_EVENT_NOTIFICATIONS,
  SET_CLIENT_DATA,
  USER_AUTH_LOADING_START,
  USER_AUTH_LOADING_END,
} from '../constants';
import { apiRequest } from '../../../global-utils/api-request';
import { loginUser } from '.';
import storageAndCookie from '../../../global-utils/storage-and-cookies';
import useModuleSettings from '../../../global-hooks/useModuleSettings';

export const setCurrentAppLocation = (loc) => ({
  type: SET_CURRENT_LOCATION,
  payload: loc,
});
export const openMobileMenu = () => ({
  type: APP_MOBILE_MENU_OPEN,
  payload: {},
});
export const closeMobileMenu = () => ({
  type: APP_MOBILE_MENU_CLOSE,
  payload: {},
});
export const toggleMobileMenu = () => ({
  type: APP_MOBILE_MENU_TOGGLE,
  payload: {},
});
export const openTopNavMenu = (topNavMenuAnchor) => ({
  type: APP_TOPNAV_MENU_OPEN,
  payload: { topNavMenuAnchor },
});
export const closeTopNavMenu = () => ({
  type: APP_TOPNAV_MENU_CLOSE,
  payload: {},
});
export const setAppReady = (isReady, settings) => (dispatch) => {
  // Now need to load modules :)
  const allCompsToCore = [];
  if (settings?.modules) {
    Object.keys(settings.modules).forEach((moduleName) => {
      // First - load modules on page load only needed on page load
      loadSingleModules(settings.modules[moduleName], false);

      // Now we need to make sure that the components that are required to the page will be loaded
      allCompsToCore.push(...settings.modules[moduleName].componentsToCore);
    });
    // Now we should do something with this list of components to core.
    // allCompsToCore
  }
  dispatch({
    type: APP_SET_READY,
    payload: { settings, isReady, allCompsToCore },
  });
};

export const setPagePreview = (settings) => ({
  type: SET_TEST_FLIGHT_PAGE,
  payload: settings,
});
export const setPageParams = (pageParams) => ({
  type: SET_TEST_FLIGHT_PAGE_PARAMS,
  payload: pageParams,
});
export const setComponentsBuildingPreview = (settings) => ({
  type: SET_TEST_FLIGHT_COMPONENTS_BUILDING,
  payload: settings,
});
export const setAppReadyError = (isReady) => ({
  type: APP_SET_READY_ERROR,
  payload: isReady,
});
export const setCoreData = (coreData) => ({
  type: APP_SET_CORE,
  payload: coreData,
});

export const setIsIframe = () => ({
  type: SET_IS_IFRAME,
  payload: null,
});
export const changeCurrentPageInfo = (title, pageName) => ({
  type: APP_CHANGE_PAGE,
  payload: { title, pageName },
});

export const changeCurrentBreakpoint = (breakpoint) => ({
  type: APP_CHANGE_BREAKPOINT,
  payload: breakpoint,
});

export const setGridWithSidePreview = (isActive, content) => ({
  type: SET_GRID_WITH_SIDE_PREVIEW,
  payload: { isActive, content },
});

export const getCurrentAppSettings = (coreSettings) => (dispatch) => {
  // first set Core:
  dispatch(setCoreData(coreSettings));
  if (coreSettings.info_file_path_dev) {
    let infoFilePath = coreSettings.info_file_path_dev;

    if (infoFilePath.indexOf('http') === 0 - 1) {
      // There is no full path for the settings, use current
      infoFilePath = `${window.location.origin}/${coreSettings.info_file_path_dev}?v=${Math.random()}`;
    }

    fetch(infoFilePath)
      .then((res) => res.json())
      .then((res) => {
        // Now we got the whole structure of our app :-)
        document.title = res.appName;
        // @NODE_ENV: Replace place holders in layoutSetting with content from json component's files:
        dispatch(setAppReady(true, res));
        // dispatch(setAppReady(true, res));
      })
      .catch((e) => {
        console.error('HUGE ERROR ! We didnt get the settings for this VM', e);
        // TODO: Check if we're on development env and trigger temp
      });
  } else if (coreSettings.info_translation_key) {
    // alert(`I AM HERE${coreSettings.info_translation_key}`);
    const url = `https://${coreSettings.server_addr}/v1/translationkey_mappings.json`;
    const body = {
      page: 1,
      include_translations: 1,
      include_translationkey: 1,
      info_translation_key: coreSettings.info_translation_key,
      include_translatable: 1,
      smart_find: 1,
      translatable_id: coreSettings.event_id,
      translatable_type: 'Event',
    };
    apiRequest({ url, body })
      .then((res) => {
        // console.log('Wohoo', res);
        if (res.data.length === 1) {
          if (res.data[0].translationkey.translations.length > 0) {
            const currentSettings = res.data[0].translationkey.translations.find((t) => (t.language === coreSettings.info_translation_key));
            if (currentSettings) {
              try {
                const newSettings = JSON.parse(currentSettings.value);
                dispatch(setAppReady(true, newSettings));
              } catch (e) {
                console.log('Error parsing info!!', e);
              }
            } else {
              // Didnt find settings.
              console.log('Error getting settings');
            }
          } else {
            console.log('No translations?', res.data);
          }
        } else {
          console.log('No translations', res);
        }
      });
  } else if (coreSettings.info_file_path) {
    let infoFilePath = coreSettings.info_file_path;
    if (infoFilePath.indexOf('http') === 0 - 1) {
      // There is no full path for the settings, use current
      infoFilePath = `${window.location.origin}/${coreSettings.info_file_path}?v=${Math.random()}`;
    }

    fetch(infoFilePath)
      .then((res) => res.json())
      .then((res) => {
        // Now we got the whole structure of our app :-)
        document.title = res.appName;
        // @NODE_ENV: Replace place holders in layoutSetting with content from json component's files:
        dispatch(setAppReady(true, res));
      })
      .catch((e) => {
        console.error('HUGE ERROR ! We didnt get the settings for this VM', e);
        // TODO: Check if we're on development env and trigger temp
      });
  } else {
    // TODO: dispatch critical error
  }
};

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const prepareApp = () => (dispatch) => {
  // set timeout of 3 seconds to show the splash screen
  // Here get core settings
  const isIframe = inIframe();
  const filePath = `${window.location.origin}/coreSettings.json?v=${Math.random()}`;
  // if (process.env.NODE_ENV !== 'development') {
  //   dispatch(setCurrentAppLocation(window.location.pathname.substring(0, window.location.pathname.length - 1)));
  //   filePath = `./coreSettings.json?v=${Math.random()}`;
  // }
  if (!isIframe) {
    fetch(
      filePath
      // `./coreSettings.json?v=${Math.random()}`,
      // `${window.location.origin}/coreSettings.json?v=${Math.random()}`,
      // `${window.location.origin}${window.location.pathname}/coreSettings.json?v=${Math.random()}`,
    )
      .then((res) => res.json())
      .then((res) => {
        // Here we got the settings for our app
        // event_id, server, etc...
        const clonedRes = { ...res };
        const eventId = clonedRes.event_id;
        if (window.location.hash === '#staging') {
          storageAndCookie.set(`JmeENV_${eventId}`, 'true');
          // https://stackoverflow.com/questions/1397329/how-to-remove-the-hash-from-window-location-url-with-javascript-without-page-r/5298684#5298684
          window.history.pushState('', document.title, window.location.pathname + window.location.search);
        }
        if (storageAndCookie.get(`JmeENV_${eventId}`)) {
          clonedRes.info_translation_key += '_staging';
        }
        dispatch(getCurrentAppSettings(clonedRes));
      })
      .catch((e) => {
        console.log('HUGE ERROR ! NO SETTINGS FILE.. !! ', e);
        // TODO: Check if we're on development env and trigger temp
        dispatch(setAppReadyError());
      });
  } else {
    dispatch(setIsIframe());
  }
};

export const ocaLogin = ({ uid, accessToken }) => (dispatch, getState) => {
  const state = getState();
  const { instance } = useModuleSettings('JMTLogin');
  const instanceUser = `${instance}.user`;

  const url = `https://${state?.appState?.core.server_addr}/v1/logins/${uid}.json`;
  const body = {
    access_token: accessToken,
    include_user: 1,
    include_grants: 1
  };
  apiRequest({ url, body })
    .then((res) => {
      if (!res?.data?.error_code && !res?.data?.error_massge) {
        dispatch(loginUser(res.data));
        storageAndCookie.set(instanceUser, JSON.stringify({ accessToken: res.data.manage_jwt_token }));
      }
    }).catch((err) => console.error('ocaLogin err ', err));
};

export const setEventNotification = (notifiactionKey, notificationValue) => ({
  type: SET_EVENT_NOTIFICATIONS,
  payload: { [notifiactionKey]: notificationValue }
});

export const setClientData = (key, data) => ({ type: SET_CLIENT_DATA, payload: { key, data } });

export const userAuthLoadingStart = () => ({
  type: USER_AUTH_LOADING_START
});

export const userAuthLoadingEnd = () => ({
  type: USER_AUTH_LOADING_END
});
