/**
 * Set of functions that manipulate with API calls from:
 *   - v1/resources.json
 *   - v1/resources/{id}.json
 */
import { isNumber } from '../../globalUtils';

/** this constant exist, because the access to the medable_type is only in the
 * media_items.json call, however this constant is refered also in other context:
 */
const RESOURCE_MEDIABLE_TYPE = 'Resourcegroup';

/**
 * Accept a resource object and convert to object expected by MediaCard vmTemplate.
 * @param {Object} resourceItem DB entity of a single resource
 */
function resourceToMediaCard(resourceItem = {}, options = {}) {
  const {
    defaultImage,
    themeColor,
    uiTitle: mediaTypeUiTitle,
  } = options[
    resourceItem?.resourcetype?.title || resourceItem?.resourcetype_title
  ] ?? {};
  const {
    path: resourceItemPath,
    is_published: isPublished,
    is_handled: isHandled,
    is_approved: isApproved,
    is_uploaded: isUploaded,
    resourcetype_title: resourcetypeTitle,
    jpg_path: jpgPath,
  } = resourceItem;
  return {
    ...resourceItem,
    endsAt: resourceItem?.ends_at,
    href: `/resources/${resourceItem?.slug}`,
    image:
      resourceItem?.thumb_url
        ?.replace('thumbs/preview.gif', 'preview.png')
        .replace('thumbs/Slidethumb1.jpg', 'preview.png')
      ?? resourceItem.preview_url
      ?? defaultImage,
    startsAt: resourceItem?.starts_at,
    subTitle: resourceItem?.contentsession?.title,
    views: resourceItem?.playbacks_count,
    mediableTypeUiTitle: mediaTypeUiTitle,
    mediableTypeColor: themeColor,
    tags: resourceItem?.tags?.map((el) => el?.tag).join(', '),
    vmIfShow: !!(resourcetypeTitle !== 'manage_pdf_abstract' && isHandled),
    vmIsClickable: !!(
      resourceItemPath
      && resourcetypeTitle !== 'manage_pdf_abstract'
      && isHandled
      && isApproved
      && isPublished
      && isUploaded
    ),
    vmJpgPath: jpgPath ?? defaultImage,
  };
}

function resourcesToMediaCard(resources = [], options = {}) {
  return resources
    .filter((el) => isNumber(el.id)) // this rejects elements with id === null or undefined
    .map((el) => resourceToMediaCard(el, options));
}

function filteredResourcesToMediaCard(apiRes = {}, options = {}) {
  const { id: thisResourceId, contentsession = {} } = apiRes;
  const { resourcegroups = [] } = contentsession;
  const resources = [];

  // the resources are burried few levels down the API responce. First we create
  // an array of all resources, then we filter out only relevant items:
  resourcegroups
    .map((el) => el?.body?.resources)
    .forEach((el) => resources.push(...el));

  return resources
    .filter(
      (el) => el?.id !== thisResourceId // filter self and also reject elements with id === null or undefined
        && el?.type === 'Content'
        && isNumber(el?.id)
    ) // presentations & ePoster
    .map((el) => resourceToMediaCard(el, options));
}

function showAbstract(resourcegroups = []) {
  const slug = resourcegroups?.resources?.find(
    (resource) => resource?.type === 'Abstract'
      || resource?.resourcetype_title === 'manage_pdf_abstract'
  )?.slug;
  if (slug) {
    return `/resources/${slug}`;
  }
  return null;
}

function getPresentationDescription(resources = []) {
  const description = resources.find(
    (resource) => resource?.resourcetype_title === 'sound_presentation'
      && resource?.type === 'Content'
  )?.description;
  return description;
}

export {
  resourceToMediaCard,
  resourcesToMediaCard,
  filteredResourcesToMediaCard,
  RESOURCE_MEDIABLE_TYPE,
  showAbstract,
  getPresentationDescription,
};
