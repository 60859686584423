import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeArrow = (componentStyles) => `
  .jme-arrow {
    background: #7070709e;
    opacity: 0.4;
    transition: 0.3s;
    position: absolute;
    bottom: 0%;
    font-size: 3rem;
    color: white;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-arrow:hover {
    background: #707070e0;
    opacity: 1;
  }
  .right-up-round {
    border-radius: 0px 8px 0px 0px;
  }
  .left-up-round {
    border-radius: 8px 0px 0px 0px;
  }
  .left-down-round {
    border-radius: 0px 0px 0px 8px;
  }
  .right-down-round {
    border-radius: 0px 0px 8px 0px;
  }
  .up {
    top: 0%;
  }
  .down {
    bottom: 0%;
  }
  .right {
    right: 0px;
  }
  .left {
    left: 0px;
  }
  .jme-side-arrow {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 10;
  }
`;

export default stylesJmeArrow;
