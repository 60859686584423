import React from 'react';
import PropTypes from 'prop-types';
import * as allPages from '../../pages';

const DynamicPageRenderer = (props) => {
  // make sure page exists:
  const { pageName } = props;
  const PageComp = allPages[pageName];
  if (!PageComp) {
    console.error("Page Doesn't exists", props);
    // TODO: redirect to 404
    return 'Page Not Found 404';
  }
  return (
    <PageComp {...props} />
  );
};

DynamicPageRenderer.propTypes = {
  pageName: PropTypes.string.isRequired, // Must get a page name
};

export default DynamicPageRenderer;
