import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiRequestProcessor } from '../global-state/redux/actions';
import { reWriteProps } from '../global-utils/map-data-to-props';
import vmFunctions from '../global-utils/vmFunctions';

/**
 * @summary Create short access mask.
 * @description The apiDataInfo has to have modelName and apiEndpointName members. These fields have to
 * agree with derinitions in adminModels.
 * @param {object} apiDataInfoItme - API data info.
 * @returns {string} Mask to acces data via apiData property.
 * @example
 * getPropsMask({modelName: "news", apiEndpointName: "single"})
 * // returns 'news_single'
 */
export function getPropsMask(apiDataInfoItem) {
  const { modelName, apiEndpointName } = apiDataInfoItem;
  return `${modelName}_${apiEndpointName}`;
}

/**
 * @description All the variables of an API call are contained in url and body. Use it to create unique ASCI string and use this string as a mask in store.data. This method is UTF8 safe.
 * @summary Create unique ASCI string from information in url and body.
 * @param {object} apiDataInfoItme - API data info.
 * @returns {string} ASCI string
 * @example
 * getStoreMask({url: "v1/guidelines/23.json", body: {include_sponsors: 1})
 * // returns similar to: history_L3YxL2d1aWRlbGluZXMvMjEuanNvbnsiZXZlbnRfaWQiOjMyMywiaW5
 */
export function getStoreMask(apiDataInfoItem) {
  const { url, body } = apiDataInfoItem;
  return `history_${Buffer.from(`${url}${JSON.stringify(body)}`).toString(
    'base64'
  )}`;
}

/**
 * Debounce API call if:
 * a) we have data not older then refreshRate,
 * b) we are curently loading this API request.
 * We pass API request if: a) there is not history of this call, b) the data is older then refreshRate.
 * @summary Prevent API call if we have available up-to-date data at hand.
 * @param {array} dataInfo - history of the previous API calls
 * @param {object} apiDataInfoItem - array of API request information
 * @returns {boolean}
 */
export function ifToDebounceApiCall(dataInfo, apiDataInfoItem) {
  const { storeMask, refreshRate = 5 * 60 * 1000 } = apiDataInfoItem; // default debounce interval : 5min
  if (!dataInfo?.[storeMask]) return false;
  if (dataInfo?.[storeMask].isLoading) return true;
  return Date.now() - dataInfo[storeMask]?.timestamp < refreshRate;
}

/**
 * @summary Bring data from API calls, one dataset per apiDataInfo item.
 * @param {array} apiDataInfo - array of API reqest information
 * @param {object} urlParams - current url parasm
 * @returns {array} set of API responses based on apiDataInfo array
 */
const useAPIDataHook = (apiDataInfo = [], urlParams, urlSearchParams) => {
  const [apiData, setApiData] = useState({});
  const store = useSelector((state) => state);
  const dataInfo = useSelector((state) => state.data);
  const dispatch = useDispatch();

  // useEffect together with useState solve the problem:
  // Warning: Cannot update a component (`DynamicCompRenderer`) while rendering a different component (`PageRenderer`)
  // To follow the stack: https://reactjs.org/link/setstate-in-render
  useEffect(() => {
    const apiDataNew = apiDataInfo.reduce((acc, entry) => {
      const entryReplaced = reWriteProps({
        vmFunctions,
        urlParams,
        store,
        urlSearchParams: urlSearchParams ? Object.fromEntries(new URLSearchParams(urlSearchParams)) : {}
      }, entry);
      entryReplaced.propsMask = getPropsMask(entryReplaced);
      entryReplaced.storeMask = getStoreMask(entryReplaced);
      if (ifToDebounceApiCall(dataInfo, entryReplaced)) {
        return {
          ...acc,
          [entryReplaced.propsMask]: dataInfo[entryReplaced.storeMask],
        };
      }
      // TODO: something is missing here, I think that debouncing do not prevent call from happening
      dispatch(apiRequestProcessor(entryReplaced));
      return acc;
    }, {});
    // set new state only if any of the api responses are new:
    if (Object.keys(apiDataNew).some((el) => apiData[el] !== apiDataNew[el])) {
      setApiData(apiDataNew);
    }
  }, [apiDataInfo, urlParams, urlSearchParams, dataInfo]);

  return apiData;
};

export default useAPIDataHook;
