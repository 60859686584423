const isModuleFileAlreadyImported = (moduleImportId) => !!document.getElementById(moduleImportId);

const fileLoadingQueue = [];

export const loadjscssfile = (loadObj, doneCallback) => {
  // console.log('fileLoadingQueue', { loadObj, fileLoadingQueue });
  if (isModuleFileAlreadyImported(loadObj.path)) {
    doneCallback(loadObj);
    return;
  }
  let fileref;
  if (loadObj.filetype === 'js') { // if filename is a external JavaScript file
    fileref = document.createElement('script');
    fileref.type = 'text/javascript';
    // fileref.id = loadObj.id;
    fileref.id = loadObj.path;
    console.log('LOAD FILE:', loadObj.path.substr(-20));
    fileref.src = loadObj.path;
    fileref.async = false;
    fileref.onload = () => {
      doneCallback(loadObj);
    };
    fileref.onerror = () => {
      doneCallback(loadObj);
    };
    document.getElementsByTagName('head')[0].appendChild(fileref);
  } else if (loadObj.filetype === 'css') { // if filename is an external CSS file
    fileref = document.createElement('link');
    fileref.setAttribute('rel', 'stylesheet');
    fileref.setAttribute('type', 'text/css');
    // fileref.setAttribute('id', loadObj.id);
    fileref.setAttribute('id', loadObj.path);
    fileref.setAttribute('href', loadObj.path);
    fileref.onload = () => {
      doneCallback(loadObj);
    };
    fileref.onerror = () => {
      doneCallback(loadObj);
    };
    document.getElementsByTagName('head')[0].appendChild(fileref);
  } else {
    console.warn('Could not import file: ', loadObj);
  }
};

const goGetFileIfPossible = () => {
  if (fileLoadingQueue.length && !fileLoadingQueue.find((obj) => obj.isLoading)) {
    // No such entry has isLoading, so go get
    const nextItem = { ...fileLoadingQueue[0], isLoading: true };
    loadjscssfile(nextItem, (loadObj) => {
      // const tempIdx = fileLoadingQueue.findIndex((obj) => obj.id === loadObj.id);
      const tempIdx = fileLoadingQueue.findIndex((obj) => obj.path === loadObj.path);
      if (tempIdx > 0 - 1) {
        fileLoadingQueue.splice(tempIdx, 1);
      }
      goGetFileIfPossible();
    });
  }
};

const loadFileQueue = (loadObj) => {
  fileLoadingQueue.push(loadObj);
  goGetFileIfPossible();
};

// Should load all of the modules from layoutSettings.modules
export const loadSingleModules = (vmModule = {}, forceLoadingAll = false) => {
  if (vmModule?.isActive) {
    // If the modules should be active -> load files.
    vmModule.imports.forEach((importObj) => {
      if ((!forceLoadingAll && importObj.onPageLoad) || forceLoadingAll) {
        // If it's not page loading - means we are using this component now
        // if not
        if (!isModuleFileAlreadyImported(importObj.path)) {
          // loadjscssfile(importObj);
          loadFileQueue(importObj);
        // } else {
        //   console.log('MODULE Is already included:', importObj);
        }
      }
    });
  }
};
export const ResetSingleModulesImports = (vmModule = {}) => {
  // remove all imports
  vmModule.imports.forEach((importObj) => {
    console.log('REMOVE file', importObj.path);
    document.getElementById(importObj.path).remove();
  });

  // add imports again.
  vmModule.imports.forEach((importObj) => {
    console.log('ADD IMPORT file', importObj.path);
    loadFileQueue(importObj);
  });
};
export const loadAllModules = (modulesArr = [], forceLoadingAll = false) => {
  modulesArr.forEach((vmModule) => {
    loadSingleModules(vmModule, forceLoadingAll);
  });
};
