import { componentStylesToCss } from '../componentStylesUtils';

const stylesJMETabs = (componentStyles) => `
  .qnaVotingHtmlWrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .countdownWrapper {
    margin-bottom: 20px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .tabWrapper {
    margin: 10px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .tabHeaderWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #001b71;
    height: 60px;
    ${componentStylesToCss(componentStyles?.tabHeaderWrapper)}
  }
  .tabLink {
    border: none;
    outline: none;
    cursor: pointer;
    color: #7f8db8;
    background-color: transparent;
    place-self: center;
    ${componentStylesToCss(componentStyles?.tabLink)}
  }
  .tabLink:hover {
    color: #c6c6c6;
    ${componentStylesToCss(componentStyles?.tabLinkOnHover)}
  }
  .tabLinkActive {
    color: #fff;
    ${componentStylesToCss(componentStyles?.tabLinkActive)}
  }
  .tabContentWrapper {
    ${componentStylesToCss(componentStyles?.tabContentWrapper)}
  }
  .tabContent {
    ${componentStylesToCss(componentStyles?.tabContent)}
  }
`;

export default stylesJMETabs;
