import { combineReducers } from 'redux';
import apiReducer from './api-reducer';
import userReducer from './user-reducer';
import appStateReducer from './app-state-reducer';

export default combineReducers({
  data: apiReducer,
  user: userReducer,
  appState: appStateReducer,
});
