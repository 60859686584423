import { setClientData } from '../../global-state/redux/actions';
import { getValueByVmPath } from '../vmPath';
import * as vmApi from './vmApi';
import * as components from './components';
import storageAndCookie from '../storage-and-cookies';
import { isArr } from '../globalUtils';

const vmFunctions = {
  dateTime: {
    nowInMs: () => Date.now(),
    nowInIso: () => new Date().toISOString(),
    isToday: (timestamp) => {
      const date = new Date(timestamp);
      const now = new Date();
      return (
        date.getDate() === now.getDate()
        && date.getMonth() === now.getMonth()
        && date.getFullYear() === now.getFullYear()
      );
    },
    isDateAfterNow: (date) => {
      // date should be a string as we get it from back
      if (!date) return false;

      // Converting the date and time into number e.g 202203081646745053000
      const givenDate = new Date(date).getTime();

      return Date.now() < givenDate;
    }
  },
  string: {
    toUpperCase: (str) => str.toUpperCase(),
    toLowerCase: (str) => str.toLowerCase(),
    join: (...args) => args.join(''),
    equals: (arg1, arg2) => String(arg1) === String(arg2),
    collapse: (str, maxLength) => (str.length > maxLength ? `${str.substring(0, maxLength)}...` : str),
  },
  array: {
    map: (arr, func = (el) => el) => arr.map(func),
    some: (arr, func = (el) => el) => arr.some(func),
    every: (arr, func = (el) => el) => arr.every(func),
    includes: (arr, el) => arr.includes(el),
    isStrInArr: (arr, str) => arr.includes(str),
    removePastDates: (arr) => {
      // this is use for news call (that have ends_at key in the obj)
      if (!isArr(arr)) return [];
      return arr?.filter((el) => vmFunctions?.dateTime?.isDateAfterNow(el?.ends_at));
    },
    filterBasedOnId: (arr, id) => arr?.filter((el) => el.id === id)
  },
  logic: {
    ifGreaterThan: (a, b, trueVal, falseVal) => (a > b ? trueVal : falseVal),
    vmIfCondition: (condition, trueRes, falseRes) => (condition ? trueRes : falseRes),
  },
  filters: {
    filterByVmPath: (arr = [], vmPath = []) => arr.filter((el) => !!getValueByVmPath(el, vmPath)),
    filterSponsorsImagesByPath: (arr, ...path) => arr?.filter((el) => {
      const parsedSettings = JSON.parse(el.settings);
      const myRes = path.reduce((acc, next) => acc[next], parsedSettings);
      return !!myRes;
    })
  },
  setClientData: (key, data) => setClientData(key, data),
  setCookie: (name, days) => () => storageAndCookie.set(
    name,
    `${window.location.pathname}${window.location.search}`,
    days
  ),
  exampleAction: (val) => ({ type: 'EXAMPLE_ACTION', payload: val }),
  filterBasedOnWord: (word, word2) => word !== word2,
  customVmApi: {
    filterCurrentResource: (arr = [], currentId) => arr.filter((el) => el?.id !== currentId),
    filterCurrentResourceFromResourcegroups: (
      resourcegroups = [],
      currentId
    ) => {
      // Filtering current resource from resourcegroups and resourcetype_title === sound_presentation
      // (for presentation of the session - presentation no access)
      const filtered = [];
      resourcegroups.map((resourcegroup) => resourcegroup?.body?.resources?.filter((resource) => {
        if (
          resource?.id !== currentId
            && resource.resourcetype_title === 'sound_presentation'
        ) {
          filtered.push(resource);
        }
        return null;
      }));
      return filtered?.length === 0 ? null : filtered;
    },
  },
  vmApi,
  components,
};

vmFunctions.exampleAction.isReduxAction = true;
vmFunctions.setClientData.isReduxAction = true;

export default vmFunctions;
