import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { componentsPropType, apiDataInfoPropType } from '../../global-prop-types';
import useAPIDataHook from '../../global-hooks/useAPIDataHook';
import { changeCurrentPageInfo } from '../../global-state/redux/actions';
import ComponentsArrRenderer from './ComponentsArrRenderer';
import { Loading } from '../global';

const PageRenderer = (props) => {
  // here should get the Data :)
  // TODO: Use urlParams here
  const {
    title, pageName, apiDataInfo, urlParams, urlSearchParams, handlerList, ...restProps
  } = props;
  const apiData = useAPIDataHook(apiDataInfo, urlParams, urlSearchParams);
  const isReady = Object.values(apiData).every((el) => !!el.isReady);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeCurrentPageInfo(title, pageName));
  }, [title]);

  if (restProps?.forceData && apiData) {
    // Force data - comes from preview pages ( Test-flight )
    // Avoid using it in our layoutSettings.
    if (apiData[restProps.forceData.dataMask]) {
      apiData[restProps.forceData.dataMask].data = restProps.forceData.data;
    }
  }

  // TODO: Page Error handling

  if (!isReady) return <Loading />;
  return (
    <Suspense fallback={<Loading />}>
      <ComponentsArrRenderer {...restProps} handlerList={handlerList} apiData={apiData} parent={['PAGE']} />
    </Suspense>
  );
};

PageRenderer.propTypes = {
  title: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  components: componentsPropType.isRequired,
  apiDataInfo: apiDataInfoPropType,
  // TODO: Add this to global prop types
  urlParams: PropTypes.shape({
    id: PropTypes.string,
  }),
  urlSearchParams: PropTypes.string,
  handlerList: PropTypes.shape({}),
};

export default PageRenderer;
