import PropTypes from 'prop-types';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useRoutes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Navbar from './header/Navbar';
import HeadBanner from './header/HeadBanner';
import DynamicPageRenderer from './core/DynamicPageRenderer';
import useBreakpoint from '../global-hooks/useBreakpoint';
import { changeCurrentBreakpoint } from '../global-state/redux/actions';
import TestFlightPage from '../pages/TestFlightPage';
import OcaPage from '../pages/OcaPage';
import LogoutPage from '../pages/LogoutPage';
import AnyErrorBoundary from './global/AnyErrorBoundary';
import { RenderCoreComponents } from './core';
import { Loading } from './global';
import useShallowEqualSelector from './core/useShallowEqualSelector';
import useTheme from '../global-hooks/useTheme';
import storageAndCookie from '../global-utils/storage-and-cookies';
import Staging from './Others/Staging';
import ComponentsArrRenderer from './core/ComponentsArrRenderer';

const Temp404 = () => {
  console.log('You got 404!');
  return '!404! TEMP 404 Page not found';
};

const AppWrapper = (props) => {
  const { allComps } = props;
  const { appContainer } = useTheme();
  const { isAuthLoading, core } = useShallowEqualSelector((state) => state.appState);
  const { event_id: eventId } = core;
  const {
    appName,
    appHeader,
    appTop,
    appHeadBanner,
    appFooter,
    pages,
  } = useShallowEqualSelector((state) => state.appState.settings);
  const { title: pageTitle } = useShallowEqualSelector((state) => state.appState.currentPage);
  const {
    currentAppUrl, testFlightPageInfo, testFlightComponentsBuilding, allCompsToCore
  } = useShallowEqualSelector((state) => state.appState);
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const { pathname: currentPathname } = useLocation();
  const [lastPathname, setLastPathname] = useState(currentPathname);
  const ifScrollToTop = lastPathname !== currentPathname;
  const isStaging = storageAndCookie.get(`JmeENV_${eventId}`);

  useEffect(() => {
    dispatch(changeCurrentBreakpoint(breakpoint));
  }, [breakpoint]);

  useEffect(() => {
    if (testFlightPageInfo) {
      const gotoPage = `${currentAppUrl}/test-flight-page`;
      // console.log('TEST FLIGHT IS ON ! GO TO', gotoPage);
      navigate(gotoPage);
    }
  }, [testFlightPageInfo]);

  useEffect(() => {
    document.title = pageTitle ? `${appName} | ${pageTitle}` : appName;
  }, [appName, pageTitle]);

  useEffect(() => setLastPathname(currentPathname), [currentPathname]);

  const dynamicRouting = (routes = []) => routes.map((page) => {
    if (page) {
      return ({
        path: currentAppUrl + page.route.path,
        exact: page.route.exact,
        element: <DynamicPageRenderer
          key={page.id}
          {...page}
          allComps={allComps}
        />
      });
    }
    return { path: '3030' };
  });

  let headBannerRouting;
  if (appHeadBanner?.isActive) {
    headBannerRouting = useRoutes(
      appHeadBanner?.routes.map((headBanner) => {
        if (headBanner) {
          const { route, ...rest } = headBanner;
          return (
            {
              path: currentAppUrl + route.path,
              exact: route.exact || true,
              element: <HeadBanner {...rest} allComps={allComps} />,
            }
          );
        }
        return { path: '#' };
      })
    );
  }

  const RoutingElement = useRoutes([
    { path: `${currentAppUrl}/test-flight-page`, element: <TestFlightPage allComps={allComps} /> },
    { path: `${currentAppUrl}/oca/:uid/:accessToken`, element: <OcaPage /> },
    { path: `${currentAppUrl}/logout`, element: <LogoutPage /> },
    ...dynamicRouting(pages),
    { path: '*', element: <Temp404 /> },
  ]);
  if (ifScrollToTop) window.scrollTo(0, 0);
  if (testFlightComponentsBuilding) {
    // this is only for building in admin
    console.log('GOT THIS: testFlightComponentsBuilding', testFlightComponentsBuilding);
    return (
      <>
        <Suspense fallback={<Loading />}>
          <ComponentsArrRenderer components={testFlightComponentsBuilding} allComps={allComps} />
        </Suspense>
      </>
    );
  }
  return (
    <>
      {appHeader?.isActive && <Navbar {...appHeader} allComps={allComps} />}
      {appTop?.isActive && <ComponentsArrRenderer components={appTop.components} allComps={allComps} /> /* here we are avoiding navbar */}
      {appHeadBanner?.isActive && headBannerRouting}
      <div
        className={`container-fluid appWrapper-wrapper ${appContainer?.wrapper?.variant || ''}`}
        style={appContainer?.styles?.wrapper}
      >
        <div
          className={`container jme-container p-0 ${appContainer?.content?.variant || ''}`}
          style={appContainer?.styles?.content}
        >
          {isStaging && <Staging />}
          <AnyErrorBoundary>
            {allCompsToCore?.length > 0 && (
              <Suspense fallback={<Loading />}>
                <RenderCoreComponents allCompsToCore={allCompsToCore} allComps={allComps} />
              </Suspense>
            )}
            {isAuthLoading ? <Loading /> : RoutingElement }
          </AnyErrorBoundary>
        </div>
      </div>
      {appFooter?.isActive && <ComponentsArrRenderer components={appFooter.components} allComps={allComps} />}
    </>
  );
};

AppWrapper.propTypes = {
  allComps: PropTypes.shape({})
};
export default AppWrapper;
