import { componentStylesToCss } from '../componentStylesUtils';

const stylesGuidelinesTitle = (componentStyles, theme) => `
  .guidelinesTitle-wrapper {
    width: 100%;
    border-radius: 4px;
    padding: ${theme.spacing(1.2)};
    display: flex;
    align-items: center;
    min-height: 60px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  @media screen and (min-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit}) {
    .guidelinesTitle-wrapper {
    }
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .guidelinesTitle-wrapper {
    }
  }
  @media screen and (min-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .guidelinesTitle-text {
    }
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .guidelinesTitle-text {
    }
  }
  .guidelinesTitle-title {
    font-size: 24px;
    font-weight: 300;
    flex-grow: 1;
    margin-left: 42px;
  }
  .guidelinesSponsor-logo {
    height: 100%;
    object-fit: cover;
    background-color: #FFFFFF;
    padding: 8px;
    ${componentStylesToCss(componentStyles?.sponsorLogo)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .guidelinesSponsor-logo {
      display: none;
    }
  }
`;

export default stylesGuidelinesTitle;
