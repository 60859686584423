import useShallowEqualSelector from '../components/core/useShallowEqualSelector';

const useTheme = () => {
  const { themeSettings } = useShallowEqualSelector((state) => state.appState.settings);

  if (!themeSettings) {
    return {};
  }

  // TODO: set more default settings (breakpoints / fonts / colors ...)
  let {
    spacingConfig,
    colors,
    fonts,
    breakpoints,
  } = themeSettings;
  if (!spacingConfig) {
    spacingConfig = {
      scale: 4,
      unit: 'px',
    };
  }
  if (!colors) {
    colors = {
      primary: {},
      secondary: {},
      danger: {},
      background: {},
    };
  }
  if (!fonts) {
    fonts = {
      fontWeightLight: 100,
      fontWeightMedium: 500,
      fontWeightBol: 900,
    };
  }
  if (!breakpoints) {
    breakpoints = {
      values: {
        xs: 575,
        sm: 767,
        md: 991,
        lg: 1199,
        xl: 1399,
      },
      unit: 'px',
    };
  }

  return {
    ...themeSettings,
    colors,
    fonts,
    breakpoints,
    spacing: (val) => `${val * Number(spacingConfig?.scale)}${spacingConfig?.unit}`,
  };
};

export default useTheme;
