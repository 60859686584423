import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeCarouselData = (componentStyles) => `
  .jme-caorusel-data {
    position: relative;
    overflow: hidden;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-carouseldata-indicator-wrapper {
    position: absolute;
    display: flex;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, -5px);
  }
  .jme-carousel-data-items-wrapper {
    position: relative;
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    transition: 0.4s ease;
    left: 0px; // this needed in order to make slide smoothly on first click
  }
  .jme-carousel-data-indicator {
    margin-right: 5px;
  }
  .jme-carousel-data-indicator.active {
    color: white;
    border: 1px solid black;
    border-radius: 50%;
  }
`;

export default stylesJmeCarouselData;
