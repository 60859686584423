const isObj = (obj) => typeof obj === 'object' && obj !== null && !Array.isArray(obj) && !(obj instanceof String);
const isArr = (obj) => Array.isArray(obj);
const isStr = (obj) => typeof obj === 'string' || obj instanceof String;
const isNumber = (obj) => typeof obj === 'number';

/**
 * @summary Recursively travers tree and run nodeCallback on each node.
 * @param {(object|array)} obj - entry node where recursion starts
 * @param {function} nodeCallback - function to run on each node. Arguments to the function are node and the path to the node
 * @returns depends on the nodeCallback. If nodeCallback returns first argument, then it returns
 * @example
 * obj: {
 *       componentName: 'AnyBox',
 *       showIf: [true],
 *       components: [
 *         { componentName: 'Box', showIf: [false] },
 *         { componentName: 'Box' }
 *       ]
 *     },
 * // Examples of transform functions:
 * // remove the showIf from tree:
 * nodeCallback: (obj) => { if (!isObj(obj) return obj; const { showIf, ...restObj } = obj; return restObj; };
 * //expected: { componentName: 'Typography', components: [{ componentName: 'Box' }, { componentName: 'Box' }] }
 *
 * // Count number of Box
 * let anyBoxCount = 0;
 * nodeCallback: (obj) => { if( obj.componentName === 'AnyBox') anyBoxCount += 1; return obj; };
 * //expected: anyBoxCount === 3
 */
function traverseTree(obj, nodeCallback = (objClone) => objClone, nodePath = []) {
  if (isArr(obj)) {
    const arrClone = obj.map((el, idx) => traverseTree(el, nodeCallback, [...nodePath, idx]));
    return nodeCallback(arrClone, nodePath);
  }
  if (isObj(obj)) {
    const objClone = Object.keys(obj).reduce((acc, objKey) => {
      acc[objKey] = traverseTree(obj[objKey], nodeCallback, [...nodePath, objKey]);
      return acc;
    }, {});
    return nodeCallback(objClone, nodePath);
  }
  return nodeCallback(obj, nodePath);
}

export {
  isObj, isArr, isStr, isNumber, traverseTree
};
