import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeButton = (componentStyles, theme) => `
  .jme-button-wrapper {
    border: none;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-button-wrapper:hover {
    outline: 0!important;
    ${componentStylesToCss(componentStyles?.onHover)}
  }
  .jme-button-wrapper:focus {
    outline: 0 !important;
    box-shadow: none !important;
    ${componentStylesToCss(componentStyles?.onFocus)}
  }
  .jme-button-primary,
  .jme-button-primary:hover {
    border: ${theme.colors?.primary?.main};
    color: ${theme.colors?.primary?.main};
    background-color: ${theme.colors?.primary?.contrast};
  }
  .jme-button-secondary,
  .jme-button-secondary:hover {
    color: ${theme.colors?.secondary?.main};
    background-color: ${theme.colors?.secondary?.contrast};
  }
  .jme-button-white,
  .jme-button-white:hover {
    color: ${theme.palette?.text?.primary};
    background-color: ${theme.palette?.background?.paper};
  }
  .jme-button-danger,
  .jme-button-danger:hover {
    color: ${theme.colors.danger?.main};
    background-color: ${theme.colors?.danger?.contrast};
  }
`;

export default stylesJmeButton;
