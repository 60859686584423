import * as stylesIndex from './stylesIndex';

const processComponentStyles = (componentStyles, theme) => Object.keys(stylesIndex).reduce(
  (acc, componentStyleKey) => acc
      + stylesIndex[componentStyleKey]?.(
        componentStyles?.[componentStyleKey],
        theme
      ),
  ''
);

/* This function is used in styleComponentName.js files, to convert js obj to css compliant string.
For example for a compoentStyleObj: { color: red, border-color: green } it produces
string: `color: red;\n border-color: green;` */
const componentStylesToCss = (componentStylesObj) => Object.keys(componentStylesObj || {})
  .map((el) => `${el}: ${componentStylesObj[el]};\n`.trim())
  .join('\n');

export { processComponentStyles, componentStylesToCss };
