import useShallowEqualSelector from '../components/core/useShallowEqualSelector';
import DynamicPageRenderer from '../components/core/DynamicPageRenderer';

const TestFlightPage = (props) => {
  const { testFlightPageInfo, testFlightPageParams } = useShallowEqualSelector((state) => state.appState);
  if (!testFlightPageInfo) {
    // console.log('No Test flight page');
    return 'No such Page...';
  }
  if (testFlightPageInfo.route.path.indexOf(':') > 0 - 1) {
    if (testFlightPageParams.some((elm) => elm.value === '') || testFlightPageParams.length === 0) {
      return (
        <div className="p3 m3">
          TestFlightPage: missing page parameters
        </div>
      );
    }
  }

  const pageParams = testFlightPageParams.reduce((acc, el) => ({ ...acc, [el.title]: el.value }), {});

  return (
    <DynamicPageRenderer {...props} pageParams={pageParams} pageName={testFlightPageInfo.pageName} {...testFlightPageInfo} />
  );
};

export default TestFlightPage;
