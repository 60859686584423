import { Component } from 'react';
import PropTypes from 'prop-types';

class AnyErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      // errorInfo: {},
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      // errorInfo,
    });
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      // You can render any custom fallback USI
      return (
        <div style={{ textAlign: 'center' }}>
          <h6 style={{ color: 'red' }}>Something went wrong!</h6>
        </div>
      );
    }

    return children;
  }
}

AnyErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default AnyErrorBoundary;
