export const APP_MOBILE_MENU_OPEN = 'APP_MOBILE_MENU_OPEN';
export const APP_MOBILE_MENU_CLOSE = 'APP_MOBILE_MENU_CLOSE';
export const APP_MOBILE_MENU_TOGGLE = 'APP_MOBILE_MENU_TOGGLE';
export const APP_TOPNAV_MENU_OPEN = 'APP_TOPNAV_MENU_OPEN';
export const APP_TOPNAV_MENU_CLOSE = 'APP_TOPNAV_MENU_CLOSE';
export const APP_SET_READY = 'APP_SET_READY';
export const APP_SET_READY_ERROR = 'APP_SET_READY_ERROR';
export const APP_SET_CORE = 'APP_SET_CORE';
export const APP_CHANGE_PAGE = 'APP_CHANGE_PAGE';
export const APP_CHANGE_BREAKPOINT = 'APP_CHANGE_BREAKPOINT';

export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_IS_IFRAME = 'SET_IS_IFRAME';
export const SET_TEST_FLIGHT_PAGE = 'SET_TEST_FLIGHT_PAGE';
export const SET_TEST_FLIGHT_PAGE_PARAMS = 'SET_TEST_FLIGHT_PAGE_PARAMS';
export const SET_TEST_FLIGHT_COMPONENTS_BUILDING = 'SET_TEST_FLIGHT_COMPONENTS_BUILDING';

export const SET_GRID_WITH_SIDE_PREVIEW = 'SET_GRID_WITH_SIDE_PREVIEW';

export const SET_EVENT_NOTIFICATIONS = 'SET_EVENT_NOTIFICATIONS';
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA';
export const GET_CLIENT_DATA = 'GET_CLIENT_DATA';

export const USER_AUTH_LOADING_START = 'USER_AUTH_LOADING_START';
export const USER_AUTH_LOADING_END = 'USER_AUTH_LOADING_END';
